import ApiService from "../../common/api.service";
import handleReject from "../../common/handleReject";

export default {
  methods: {
    /**
     * 获取公众号文章内语音播放网址
     * @param url
     * @param mutation
     * @returns {Promise<unknown>}
     */
    getSalonVoice(url, mutation = null){
      return new Promise(((resolve, reject) => {
        ApiService.setHeader();
        ApiService.post('/manager/other/salon-voice', {url})
        .then(response=>{
          mutation && this.$store.commit(mutation, null);
          resolve(response);
        })
        .catch(response=>{
          mutation && handleReject(this.$store, response, mutation);
          reject(response);
        })
      }))
    }
  }
}
