import {
  CREATE_ARTICLE_EPISODE,
  FIND_ARTICLE_EPISODE, GET_ARTICLE_EPISODES_LIST,
  SET_ARTICLE_EPISODE_ERROR, SWITCH_ARTICLE_EPISODE_STATE,
  UPDATE_ARTICLE_EPISODE
} from "../store/types";
import handleReject from "../common/handleReject";
import ApiService from "../common/api.service";

export default {
  data(){
    return {
      formBusy: false,
    }
  },
  methods: {
    deleteArticleEpisode(aid, id){
      this.showBusy();
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.delete(`/manager/articles/${aid}/episodes/${id}`)
        .then(response=>{
          this.hideBusy();
          resolve(response);
        })
        .catch(response=>{
          this.hideBusy();
          handleReject(this.$store, response, SET_ARTICLE_EPISODE_ERROR);
          reject(response);
        })
      })
    },
    createArticleEpisode(aid, formData){
      this.showBusy();
      return new Promise((resolve, reject) => {
        this.$store.dispatch(CREATE_ARTICLE_EPISODE, { aid, formData })
        .then(response=>{
          this.hideBusy();
          resolve(response)
        })
        .catch(response=>{
          this.hideBusy();
          reject(response);
        })
      })
    },
    updateArticleEpisode(aid, formData){
      this.showBusy();
      return new Promise((resolve, reject) => {
        this.$store.dispatch(UPDATE_ARTICLE_EPISODE, { aid, formData })
        .then(response=>{
          this.hideBusy();
          resolve(response)
        })
        .catch(response=>{
          this.hideBusy();
          reject(response);
        })
      })
    },
    findArticleEpisode(aid, id, busy = true){
      busy && this.showBusy();
      return new Promise((resolve, reject) => {
        this.$store.dispatch(FIND_ARTICLE_EPISODE, { aid, id })
        .then(response=>{
          busy && this.hideBusy();
          resolve(response)
        })
        .catch(response=>{
          busy && this.hideBusy();
          reject(response);
        })
      })
    },
    getArticleEpisodes(aid, page = 1, busy = true){
      busy && this.showBusy();
      return new Promise((resolve, reject) => {
        this.$store.dispatch(GET_ARTICLE_EPISODES_LIST, { aid, page })
        .then(response=>{
          busy && this.hideBusy();
          resolve(response)
        })
        .catch(response=>{
          busy && this.hideBusy();
          reject(response);
        })
      })
    },
    switchArticleEpisodeState(aid, id, params){
      this.showBusy();
      return new Promise((resolve, reject) => {
        this.$store.dispatch(SWITCH_ARTICLE_EPISODE_STATE, {aid, id, params})
        .then(response=>{
          this.hideBusy();
          resolve(response)
        })
        .catch(response=>{
          this.hideBusy();
          reject(response);
        })
      })
    },
  }
}
